<template>
  <v-card class="mb-5">
    <v-card-text class="pt-1">
      <custom-snackbar ref="snackbar"/>

      <v-toolbar flat class="d-flex flex-row-reverse">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>
                mdi-printer
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="exportFile('PDF')">
              <v-list-item-title>Exportar PDF</v-list-item-title>
            </v-list-item>
            <v-list-item @click="exportFile('Excel')">
              <v-list-item-title>Exportar Excel</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn color="info" class="ml-3" dark outlined @click="showAcertoAreaColhida = true">
          Acerto de Área Colhida
        </v-btn>
        <AcertoAreaColhidaDialog v-model="showAcertoAreaColhida"/>
      </v-toolbar>

      <v-row class="mt-3">
        <v-col class="py-0 mb-2 col-12 col-md-2">
          <DatePicker label="Data Início" v-model="dataInicioFilter" @input="fieldsSelected()"/>
        </v-col>

        <v-col class="py-0 mb-2 col-12 col-md-2">
          <DatePicker label="Data Fim" v-model="dataFimFilter" @input="fieldsSelected()"/>
        </v-col>

        <v-col class="py-0 mb-2 col-12 col-md-4">
          <EmpresasSelect v-model="empresasFilterValue" @input="fieldsSelected"></EmpresasSelect>
        </v-col>

        <v-col class="py-0 mb-2 col-12 col-md-4">
          <ProducoesSelect v-model="producoesFilterValue" :empresa="empresasFilterValue" multiple return-object @input="fieldsSelected"></ProducoesSelect>
        </v-col>
      </v-row>

      <v-row class="mb-1">
        <v-col class="py-0 mb-2 col-12 col-md-4">
          <TalhoesSelect v-model="talhoesFilterValue" :empresa="empresasFilterValue" :producao="producoesFilterValue" multiple @input="fieldsSelected"></TalhoesSelect>
        </v-col>

        <v-col class="py-0 mb-2 col-12 col-md-4">
          <v-select
            v-model="culturasFilterValue"
            :items="culturasFilterOptions"
            outlined
            dense
            item-text="nome"
            item-value="id"
            label="Culturas"
            @change="fieldsSelected()"
          />
        </v-col>

        <v-col class="py-0 mb-2 col-lg-3 col-md-4">
          <v-select
            v-model="variedadesCulturasFilterValue"
            :items="variedadesCulturasFilterOptions"
            outlined
            dense
            item-text="nome"
            item-value="id"
            label="Variedades"
            @change="fieldsSelected()"
          />
        </v-col>
        <v-col class="col-12 col-xs-12 col-sm-12 col-lg-1 py-0">
          <v-tooltip
            top
            v-model="pendingSearch"
            color="warning"
            max-width="139px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              max-width="20px"
              outlined
              block
              color="info"
              class="v-btn--height-fix"
              item-text="nome"
              v-bind="attrs"
              v-on="on"
              item-value="id"
              @click="getRegistros"
              :disabled="disabledCampos"
            >
              <v-icon dark>
                mdi-cached
               </v-icon>
              </v-btn>
              </template>
              <span>Busca pendente</span>
            </v-tooltip>
          </v-col>
      </v-row>

      <v-data-table
        dense
        item-key="id"
        sort-by="data_emissao_nf"
        sort-desc="true"
        show-expand
        single-expand
        :headers="tableHeaders"
        :items="indexedItems"
        :search="search"
        :items-per-page="$dataTableConfig.getItemsPerPage()"
        :footer-props="$dataTableConfig.getFooterProps()"
        :loading-text="$dataTableConfig.getLoadText()"
        :loading="loading"
        :page="page"
        :page-count="pageCount"
        :options.sync="options"
        :server-items-length="totalItems"
      >
        <template v-slot:progress>
          <v-progress-linear
            absolute
            indeterminate
            color="green"
          ></v-progress-linear>
        </template>

        <template v-slot:[`item.area_colhida`]="{ item }">
          <span class="num_margin">{{ $stringFormat.formatNumber(item.area_colhida, 0, 4) }}</span>
        </template>

        <template v-slot:[`item.data_emissao_nf`]="{ item }">
          {{ $stringFormat.dateISOToBRL(item.data_emissao_nf, true) }}
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            Motivo: {{ item.motivo ? item.motivo : '-' }}
          </td>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="item.tipo === 'acerto' && item.area_colhida > 0">
            <v-icon
              small
              class="mr-1"
              @click="editarAcerto(item)"
              v-if="false"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="dialogExcluirAcerto(item.acerto_id)"
            >
              mdi-delete
            </v-icon>
          </div>
        </template>

        <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
          <v-icon v-if="item.tipo === 'acerto' && item.motivo"
            :class="[
              'v-data-table__expand-icon',
              { 'v-data-table__expand-icon--active': isExpanded }
            ]"
            @click.stop="expand(!isExpanded)"
          >
            $expand
          </v-icon>
        </template>

        <template v-slot:no-data>
          <v-spacer />

          <div v-if="dataError">
            <v-row class="justify-center mt-1">
              <v-col class="col-lg-6">
                <custom-alert
                  :color="$label.getColor(0)"
                  :text="errorMessage"
                />
              </v-col>
            </v-row>
          </div>

          <div v-else-if="emptyResponse">
            <v-row class="justify-center my-1">
              <v-col class="col-lg-6">
                Não foram encontrados registros de colheitas para o filtro
              </v-col>
            </v-row>
          </div>

          <div v-else-if="errorLoad">
            <v-row class="justify-center mt-1">
              <v-col class="col-lg-6">
                <custom-alert
                  :color="$label.getColor(0)"
                  text="Não foi possível obter essas informações"
                />
              </v-col>
            </v-row>
          </div>

          <div class="text-center" v-else>
            <h6 class="my-5">Selecione uma Empresa e uma Produção.</h6>
          </div>
          <v-spacer />
        </template>
      </v-data-table>

      <div class="text-right mt-5">
        <h4>{{ `Quantidade Total (m²): ${$stringFormat.formatM2(totalAreaColhida)}` }}</h4>
      </div>
    </v-card-text>

    <v-dialog v-model="dialogConfirmarExclusaoAcerto" max-width="500px">
      <v-card class="bg-danger text-white">
        <v-card-title class="headline word-breaker d-flex justify-center">
          Tem certeza que deseja remover esse Acerto?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="white"
            text
            outlined
            large
            @click="dialogConfirmarExclusaoAcerto = false"
          >
            Não
          </v-btn>
          <v-btn
            color="white"
            text
            outlined
            large
            @click="excluirAcerto"
          >
            Sim
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmacaoRetiradasMudas" max-width="900px">
      <v-card class="bg-danger text-white">
        <v-card-title class="headline word-breaker d-flex justify-center text-center">
          A quantidade de grama informada ultrapassa a quantidade disponível na data informada. <strong class="mt-3">Deseja continuar?</strong>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="white"
            text
            outlined
            large
            @click="dialogConfirmacaoRetiradasMudas = false"
          >
            Não
          </v-btn>
          <v-btn color="white" text outlined large @click="salvarEdicaoAcerto">
            Sim
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditarAcerto" max-width="900">
      <v-card>
        <v-toolbar color="info" dark>
          <span class="headline">Editar Acerto de Área Colhida</span>
        </v-toolbar>
        <v-progress-linear
          v-if="loadingEditar"
          height="10px"
          absolute
          indeterminate
          color="green"
        />
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col class="col-12 col-sm-6">
                  <v-select
                    v-model="finalidade"
                    :items="finalidadeOptions"
                    label="Finalidade"
                    item-text="finalidade"
                    item-value="finalidade"
                    dense
                    outlined
                    disabled
                  />
                </v-col>
                <v-col :class="[finalidade === 'Retirada de Mudas' ?  'col-12 col-sm-4' :  'col-12 col-sm-6']">
                  <DatePicker
                    label="Data Ocorrência"
                    v-model="dataOcorrencia"
                  />
                </v-col>
                <v-col class="col-12 col-sm-2" v-show="finalidade == 'Retirada de Mudas'">
                  <v-btn
                    outlined
                    block
                    class="v-btn--height-fix"
                    @click="buscarSaldoGramaColhido"
                  >
                    <v-icon dark>
                      mdi-cached
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="finalidade === 'Transferência de Nota Fiscal'">
                <v-col class="col-12 col-sm-6">
                  <v-text-field
                    dense
                    outlined
                    label="Número Nota Fiscal"
                    :append-icon="notaFiscalInput ? 'mdi-close' : ''"
                    @click:append="notaFiscalInput = ''"
                    v-model="notaFiscalInput"
                  />
                </v-col>
                <v-col class="col-12 col-sm-4">
                  <DatePicker
                    label="Data Emissão"
                    v-model="dataEmissao"
                  />
                </v-col>
                <v-col class="col-12 col-sm-2">
                  <v-btn
                    outlined
                    :disabled="disabledBtnBuscarNotaFical"
                    block
                    class="v-btn--height-fix"
                    @click="buscarNotasFiscais"
                  >
                    <v-icon dark>
                      mdi-cached
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="buscarNotasFiscaisPelaData">
                  <v-select
                    v-model="notaFiscalSelect"
                    :items="notaFiscalOptions"
                    label="Notas Fiscais"
                    item-text="nota"
                    item-value="nota"
                    dense
                    outlined
                    :disabled="disabledCmbNotasFiscais"
                    @change="getSaldoByNotaFiscal"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-12 col-sm-6">
                  <v-text-field
                    dense
                    outlined
                    label="Área Colhida Transferida"
                    v-model="areaColhidaTransferida"
                     type="number"
                    :rules="[areaColhidaRule]"
                    @keydown="$stringFormat.validarNumero"
                  />
                </v-col>
                <v-col class="col-12 col-sm-6">
                  <v-text-field
                    dense
                    outlined
                    disabled
                    label="Saldo de Grama Colhido"
                    v-model="saldoGramaColhido"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="motivo"
                    label="Motivo"
                    dense
                    outlined
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialogEditarAcerto = false"> Cancelar </v-btn>
            <v-btn color="blue darken-1" text @click="validarSalvarEdicaoAcerto"> Salvar </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style scoped>
  .oferta-text-field {
    font-size: 0.8rem;
  }

  .snack-break-line {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: inherit;
  }
</style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

import AcertoAreaColhida from './components/AcertoAreaColhidaComponent'
import AcertoAreaColhidaDialog from './components/AcertoAreaColhidaDialog.vue'

export default {
  components: { AcertoAreaColhidaDialog, AcertoAreaColhida },

  data: function () {
    return {
      showAcertoAreaColhida: false,
      dataError: false,
      loading: false,
      emptyResponse: false,
      errorLoad: false,
      errorMessage: 'Não foi possível carregar os registros de colheitas',
      tableItems: [],
      producoesFilterOptions: [],
      empresasFilterOptions: [],
      producoesFilterValue: 0,
      empresasFilterValue: '',
      talhoesFilterOptions: [],
      talhoesFilterValue: 0,
      culturasFilterOptions: [],
      culturasFilterValue: 0,
      variedadesCulturasFilterOptions: [],
      variedadesCulturasFilterValue: 0,
      deDate: null,
      ateDate: null,
      nameFile: '',
      dataFile: [],
      headerFile: [],
      page: 1,
      pageCount: 0,
      options: {},
      totalItems: 0,
      totalAreaColhida: 0,
      dataInicioFilter: this.$date.getISOToday(),
      dataFimFilter: this.$date.getISOToday(),
      dialogConfirmarExclusaoAcerto: false,
      acertoIdExcluir: 0,
      dialogEditarAcerto: false,
      disabledBtnBuscarNotaFical: false,
      disabledCmbNotasFiscais: false,
      pendingSearch: false,
      producoesDisabled: true,
      talhoesDisabled: true,
      valid: '',
      notasFiscais: [],
      finalidadeOptions: [
        { finalidade: 'Transferência de Nota Fiscal' },
        { finalidade: 'Retirada de Mudas' },
      ],
      notaFiscalOptions: [],
      acertoIdEditar: '',
      empresaOrigem: '',
      producaoOrigem: '',
      talhaoOrigem: '',
      finalidade: '',
      dataOcorrencia: '',
      dataEmissao: '',
      saldoGramaColhido: '',
      areaColhidaTransferida: '',
      notaFiscalSelect: '',
      notaFiscalInput: '',
      motivo: '',
      loadingEditar: false,
      dialogConfirmacaoRetiradasMudas: false,
      mask: {
        thousands: '',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: true,
        allowBlank: true,
      },
    }
  },

  computed: {
    tableHeaders () {
      return [
        { text: 'Produção', value: 'producao_sigla', class: 'custom-table--header' },
        { text: 'Talhão', value: 'talhao_nome', class: 'custom-table--header' },
        { text: 'Variedade', value: 'variedade_cultura', class: 'custom-table--header' },
        { text: 'Data emissão NF', value: 'data_emissao_nf', class: 'custom-table--header' },
        { text: 'Dados', value: 'dados', class: 'custom-table--header' },
        { text: 'Área Colhida (m²)', value: 'area_colhida', align: 'right', class: 'custom-table--header' },
        { value: 'actions', class: 'custom-table--header', align: 'right p-0' },
        { value: 'data-table-expand', class: 'custom-table--header', align: 'right pl-0' },
      ]
    },

    actualDate () {
      return this.$date.getISOToday()
    },

    indexedItems () {
      return this.tableItems.map((item, index) => ({
        id: index,
        ...item,
      }))
    },

    areaColhidaRule () {
      let valid = true
      let msg = ''

      const areaColhida = parseInt(this.areaColhidaTransferida)
      const saldoGrama = parseInt(this.saldoGramaColhido)

      if (saldoGrama === '') {
        valid = false
        msg = 'Informe os dados corretamente para obter o Saldo de Grama'
        return () => valid || `${msg}`
      }

      if (saldoGrama === 0 && this.finalidade === 'Transferência de Nota Fiscal') {
        valid = false
        msg = 'Não possui Saldo de Grama para realizar o Acerto'
        return () => valid || `${msg}`
      }

      if (this.areaColhidaTransferida === '') {
        valid = false
        msg = 'Informe a Área Colhida Transferida'
        return () => valid || `${msg}`
      }

      if (areaColhida <= 0) {
        valid = false
        msg = 'Área Colhida Transferida deve ser maior que 0'
        return () => valid || `${msg}`
      }

      if (saldoGrama < areaColhida && this.finalidade === 'Transferência de Nota Fiscal') {
        valid = false
        msg = 'Área Colhida Transferida não pode ser maior que o Saldo de Grama'
        return () => valid || `${msg}`
      }

      return () => valid || `${msg}`
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },

    dialogDelete (val) {
      val || this.closeDelete()
    },
    empresasFilterValue () {
      this.producoesFilterValue = null
      this.producoesDisabled = false
      this.talhoesDisabled = false
      this.disabledCampos = false
    },

    options: {
      handler () {
        this.getRegistros()
      },
    },

    dataEmissao (data) {
      if (!this.notaFiscalInput) {
        let porData = ''
        data && !this.notaFiscalSelect ? porData = true : porData = false
        this.buscarNotasFiscaisPelaData = porData
      }
    },

    notaFiscalInput (val) {
      if (val) {
        this.buscarNotasFiscaisPelaData = false
        this.dataEmissao = ''
      }
    },
  },

  created () {},

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agrícola', route: 'agricola' },
      { title: 'Registros de Colheitas' },
    ])

    this.initialize()
  },

  methods: {
    async initialize () {
      this.getEmpresas()
      await this.getVariedadesCulturas()
      await this.getCulturas()
      await this.getRegistros()
    },
    fieldsSelected () {
      this.pendingSearch = true
    },

    getEmpresas () {
      this.empresasFilterOptions = this.$empresas.list()
    },

    async getProducoes () {
      this.producoesFilterOptions = []

      try {
        if (this.empresasFilterValue !== 0) {
          const id = parseInt(this.empresasFilterValue)
          const empresa = this.empresasFilterOptions.filter((e) => e.id === id)
          const producoes = empresa[0].producoes

          for (const producao of producoes) {
            this.producoesFilterOptions.push(producao)
          }
        }
      } catch (error) {
        console.error('Erro: ', error)
      }
    },

    async setProducoesOptions () {
      this.pendingSearch = true
      this.errorLoad = false
      this.producoesFilterOptions = []

      await ApiService.get('/producao').then((res) => {
        const producoesFiltro = []

        producoesFiltro.push({
          id: 0,
          sigla: 'Todas',
        })

        for (const producao of res.data.data) {
          if (producao.empresa_id === this.empresasFilterValue) {
            producoesFiltro.push({
              id: producao.id,
              sigla: producao.sigla,
            })
          }
        }

        this.producoesFilterOptions = producoesFiltro
      })
    },

    async getTalhoes () {
      this.talhoesFilterOptions = []
      const params = '?empresa_id=' + this.empresasFilterValue + '&producao_id=' + this.producoesFilterValue

      ApiService.get('/talhao', params).then((res) => {
        const talhoesFiltro = []

        talhoesFiltro.push({
          id: 0,
          nome: 'Todos',
        })

        for (const talhao of res.data.data) {
          talhoesFiltro.push({
            id: talhao.id,
            nome: talhao.nome,
          })
        }

        this.talhoesFilterOptions = talhoesFiltro
      })
    },

    async getCulturas () {
      this.culturasFilterOptions.push({
        nome: 'Todas',
        id: 0,
      })

      const response = await ApiService.get('cultura')

      for (const cultura of response.data.data) {
        this.culturasFilterOptions.push(cultura)
      }
    },

    async getVariedadesCulturas () {
      this.variedadesCulturasFilterOptions.push({
        nome: 'Todas',
        id: 0,
      })

      const response = await ApiService.get('variedade-cultura')

      for (const variedadeCultura of response.data.data) {
        this.variedadesCulturasFilterOptions.push(variedadeCultura)
      }
    },

    async getRegistros () {
      this.pendingSearch = false
      this.loading = true
      this.pendingSearch = false
      this.tableItems = []
      this.totalAreaColhida = 0

      if (this.empresasFilterValue === '') {
        this.producoesFilterValue = 0
        this.talhoesFilterValue = 0
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const params = {
        page,
        qtde: itemsPerPage,
        sortBy: sortBy[0],
        sortDesc: sortDesc[0],
        empresa_id: this.empresasFilterValue,
        producao_id: this.$array.pluck(this.producoesFilterValue, 'id').filter((v) => v !== ''),
        data_inicial: this.dataInicioFilter,
        data_final: this.dataFimFilter,
        variedade_cultura_id: this.variedadesCulturasFilterValue,
        cultura_id: this.culturasFilterValue,
        talhao_id: this.talhoesFilterValue,
      }

      try {
        const response = await ApiService.get('sapiens/nota-fiscal', '?' + this.$stringFormat.queryParamsRecursive(params))
        this.tableItems = response.data.data
        this.loading = false
        this.totalItems = response.data.total
        this.pageCount = response.data.pageCount

        if (this.tableItems.length === 0) {
          this.emptyResponse = true
          return false
        }

        this.emptyResponse = false
        this.calculaTotalAreaColhida(response.data.data)
      } catch (error) {
        this.loading = false
        this.dataError = true
        this.errorMessage = 'Não foi possível carregar os registros de colheitas, tente selecionar outro intervalo de tempo ou outra produção'
        console.error('Erro: ', error)
      }
    },

    getSaldoByNotaFiscal (nf) {
      const empresaId = this.empresaOrigem
      const producaoId = this.producaoOrigem
      const talhaoId = this.talhaoOrigem

      const params = `?empresa_id=${empresaId}&producao_id=${producaoId}&talhao_id=${talhaoId}&numero_nota_fiscal=${nf}`

      ApiService
        .get('/sapiens/nota-fiscal/notas-fiscais', params)
        .then((res) => {
          const notas = res.data.data

          if (notas.length > 0) {
            this.dataEmissao = this.$stringFormat.dateToISO(new Date(notas[0].data).toLocaleString())
            this.saldoGramaColhido = notas[0].area
          } else {
            this.disabledCmbNotasFiscais = true

            const msg = 'Nenhuma Nota Fiscal dísponivel para os dados informados'

            this.notaFiscalOptions.push({
              nota: msg,
            })

            this.notaFiscalSelect = msg
            this.buscarNotasFiscaisPelaData = true
          }

          this.disabledBtnBuscarNotaFical = false
          this.loading = false
        })
    },

    close () {
      this.dialog1 = false
      this.dialog2 = false
    },

    async exportFile (tipo) {
      if (this.tableItems.length > 0) {
        const url = tipo === 'Excel' ? '/relatorios/exportar' : '/relatorios/exportar-pdf'
        const filtros = {
          empresa_id: this.empresasFilterValue > 0 ? this.empresasFilterValue : '0',
          producao_id: this.$array.pluck(this.producoesFilterValue, 'id').filter((v) => v !== '').toString()
            ? this.$array.pluck(this.producoesFilterValue, 'id').filter((v) => v !== '').toString() : '0',
          talhao_id: this.talhoesFilterValue.toString() ? this.talhoesFilterValue.toString() : '0',
          cultura_id: this.culturasFilterValue > 0 ? this.culturasFilterValue : '0',
          variedade_cultura_id: this.variedadesCulturasFilterValue > 0 ? this.variedadesCulturasFilterValue : '0',
          data_inicio: this.dataInicioFilter !== '' ? this.dataInicioFilter : '0',
          data_fim: this.dataFimFilter !== '' ? this.dataFimFilter : '0',
        }
        const params = {
          relatorio: tipo === 'Excel' ? 'RegistrosColheitas' : 'registros-colheitas/registros-colheitas',
          parametros: JSON.stringify(filtros),
        }

        this.$refs.snackbar.show('Gerando o arquivo', 'Aguarde alguns segundos para baixar o arquivo', 'success', -1, true)

        ApiService.post(url, params).then((res) => {
          if (res.data.data.arquivo !== '') {
            this.$refs.snackbar.show('Arquivo gerado com sucesso', '', 'success')
            window.open(res.data.data.arquivo, '_blank')
          } else {
            this.$refs.snackbar.show('Erro', 'Não foi possível gerar o arquivo, tente novamente mais tarde.', 'danger')
          }
        }).catch((error) => {
          this.$refs.snackbar.show('Erro', 'Não foi possível gerar o arquivo, tente novamente mais tarde.', 'danger')
          console.error('Erro: ', error)
        })
      } else {
        this.$refs.snackbar.show('Erro', 'Não foram encontrados dados para exportação!', 'danger')
      }
    },

    calculaTotalAreaColhida (data) {
      let total = 0

      for (const d of data) {
        total += d.area_colhida
      }

      this.totalAreaColhida = total
    },

    dialogExcluirAcerto (acertoId) {
      this.acertoIdExcluir = acertoId
      this.dialogConfirmarExclusaoAcerto = true
    },

    excluirAcerto () {
      this.dialogConfirmarExclusaoAcerto = false
      this.$refs.snackbar.show('Aguarde', 'Excluindo Acerto(s)', 'success', -1, true)

      ApiService
        .delete(`/acerto-area-colhida/${this.acertoIdExcluir}`)
        .then((res) => {
          this.$refs.snackbar.show('Sucesso', res.data.data, 'success')
          this.getRegistros()
        })
        .catch((error) => {
          this.$refs.snackbar.show('Erro', 'Não foi possível excluir os Acerto(s)', 'danger')
          console.error('Erro: ', error)
        })
    },

    editarAcerto (acerto) {
      let dataHora = acerto.data_emissao_nf
      dataHora = dataHora.split(' ')
      const data = dataHora[0]

      this.disabledCmbNotasFiscais = true
      this.notaFiscalOptions = []
      this.notasFiscais = []
      this.notaFiscalSelect = ''
      this.saldoGramaColhido = ''

      this.acertoIdEditar = acerto.acerto_id
      this.empresaOrigem = acerto.empresa_id
      this.producaoOrigem = acerto.producao_id
      this.talhaoOrigem = acerto.talhao_id
      this.finalidade = acerto.dados === 'Retirada de Mudas' ? 'Retirada de Mudas' : 'Transferência de Nota Fiscal'
      this.dataOcorrencia = data
      this.dataEmissao = data
      this.areaColhidaTransferida = acerto.area_colhida
      this.motivo = acerto.motivo
      this.notaFiscalInput = acerto.numero_nf

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }

      this.buscarSaldoGramaColhido()

      if (this.finalidade === 'Transferência de Nota Fiscal') {
        this.buscarNotasFiscais()
        this.areaColhidaTransferida = acerto.area_colhida
      }

      this.dialogEditarAcerto = true
    },

    validarSalvarEdicaoAcerto () {
      if (this.$refs.form.validate()) {
        if (this.finalidade === 'Retirada de Mudas') {
          if (parseInt(this.saldoGramaColhido) < this.areaColhidaTransferida) {
            this.dialogConfirmacaoRetiradasMudas = true
          } else {
            this.salvarEdicaoAcerto()
          }
        } else {
          this.salvarEdicaoAcerto()
        }
      }
    },

    salvarEdicaoAcerto () {
      this.dialogConfirmacaoRetiradasMudas = false
      this.dialogEditarAcerto = false

      let nf = ''

      if (this.finalidade === 'Transferência de Nota Fiscal') {
        nf = this.notaFiscalInput !== '' ? this.notaFiscalInput : this.notaFiscalSelect
      }

      const data = {
        area_colhida: this.areaColhidaTransferida,
        observacao: this.finalidade,
        data: this.dataOcorrencia,
        motivo: this.motivo,
        nf,
      }

      ApiService
        .put(`/acerto-area-colhida/${this.acertoIdEditar}`, data)
        .then(
          () => {
            this.$refs.snackbar.show('Sucesso', 'Acerto(s) editados', 'success')
            this.getRegistros()
          })
        .catch((error) => {
          this.$refs.snackbar.show('Erro', 'Não foi possível editar os Acerto(s)', 'danger')
          console.error('Erro: ', error)
        })
    },

    buscarNotasFiscais () {
      this.loadingEditar = true
      this.disabledBtnBuscarNotaFical = true
      this.saldoGramaColhido = ''
      this.areaColhidaTransferida = ''
      this.notasFiscais = []
      this.notaFiscalOptions = []

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }

      const empresaId = this.empresaOrigem
      const producaoId = this.producaoOrigem
      const talhaoId = this.talhaoOrigem
      const nf = this.notaFiscalInput
      const data = this.dataEmissao

      let params = `?empresa_id=${empresaId}&producao_id=${producaoId}&talhao_id=${talhaoId}&`

      if (nf) {
        this.buscarNotasFiscaisPelaData = false
        this.notaFiscalSelect = ''
        params += `numero_nota_fiscal=${nf}`
      } else {
        this.buscarNotasFiscaisPelaData = true
        params += `data_emissao=${data}`
      }

      ApiService
        .get('/sapiens/nota-fiscal/notas-fiscais', params)
        .then((res) => {
          const notas = res.data.data

          if (notas.length > 0) {
            if (nf) {
              this.dataEmissao = this.$stringFormat.dateToISO(new Date(notas[0].data).toLocaleString())
              this.saldoGramaColhido = notas[0].area
            } else {
              this.disabledCmbNotasFiscais = false
              this.notaFiscalOptions = notas
              this.notasFiscais = notas
            }
          } else {
            this.disabledCmbNotasFiscais = true

            const msg = 'Nenhuma Nota Fiscal dísponivel para os dados informados'

            this.notaFiscalOptions.push({
              nota: msg,
            })

            this.notaFiscalSelect = msg
            this.buscarNotasFiscaisPelaData = true
          }

          this.disabledBtnBuscarNotaFical = false
          this.loadingEditar = false
        })
    },

    buscarSaldoGramaColhido () {
      if (this.finalidade === 'Retirada de Mudas') {
        this.loadingEditar = true

        const empresaId = this.empresaOrigem
        const producaoId = this.producaoOrigem
        const talhaoId = this.talhaoOrigem
        const data = this.dataOcorrencia

        const params = `?empresa_id=${empresaId}&producao_id=${producaoId}&talhao_id=${talhaoId}&data_ocorrencia=${data}`

        ApiService
          .get('/sapiens/nota-fiscal/saldo-disponivel-colheita', params)
          .then((res) => {
            this.saldoGramaColhido = res.data.data
            this.loadingEditar = false
          })
      }
    },
  },
}
</script>
