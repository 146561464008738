<template>
  <v-dialog v-model="model" transition="dialog-bottom-transition" max-width="900">
    <custom-snackbar ref="snackbar" />
    <v-card>
      <v-toolbar dark color="info">
        <span class="headline">Acerto de Área Colhida</span>
      </v-toolbar>

      <v-form ref="form" lazy-validation v-show="showForm">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                    label="Finalidade *"
                    item-value="id"
                    item-text="nome"
                    dense
                    outlined
                    :rules="rule('finalidade')"
                    v-model="form.finalidade"
                    :items="finalidadeOptions"
                />
              </v-col>
              <v-col cols="6">
                <DatePicker
                    label="Data Ocorrência *"
                    v-model="form.dataOcorrencia"
                    :rules="rule('dataOcorrencia')"
                    :max="dataAtual"
                />
              </v-col>
              <v-col cols="6">
                <bloco-informativo :bordered="form.finalidade === 3" :texto-informativo="form.finalidade === 3 ? 'Origem não tera movimentação, apenas detectara nota para saldo de transferência': ''">
                  <v-row>
                  <v-col cols="12">
                    <EmpresasSelect
                        label="Empresa Origem"
                        v-model="form.empresaOrigemId"
                        :disabled="!form.finalidade"
                        :rules="rule('empresaOrigemId')"
                        obrigatorio
                    />
                  </v-col>
                  <v-col cols="12">
                    <ProducoesSelect
                        label="Produção Origem"
                        v-model="form.producaoOrigemId"
                        :empresa="form.empresaOrigemId"
                        :disabled="!form.empresaOrigemId"
                        :rules="rule('producaoOrigemId')"
                        obrigatorio
                    />
                  </v-col>

                  <v-col cols="12">
                    <TalhoesSelect
                        label="Talhão Origem"
                        v-model="form.talhaoOrigemId"
                        :empresa="form.empresaOrigemId"
                        :producao="form.producaoOrigemId"
                        :disabled="!form.producaoOrigemId"
                        :rules="rule('talhaoOrigemId')"
                        obrigatorio
                    />
                  </v-col>
                </v-row>
                </bloco-informativo>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-col cols="12">
                    <EmpresasSelect
                        label="Empresa Destino"
                        v-model="form.empresaDestinoId"
                        :disabled="![1,3].includes(form.finalidade)"
                        :obrigatorio="[1,3].includes(form.finalidade)"
                        :rules="rule('empresaDestinoId')"
                    />
                  </v-col>
                  <v-col cols="12">
                    <ProducoesSelect
                        label="Produção Destino"
                        v-model="form.producaoDestinoId"
                        :empresa="form.empresaDestinoId"
                        :disabled="!form.empresaDestinoId"
                        :obrigatorio="[1,3].includes(form.finalidade)"
                        :rules="rule('producaoDestinoId')"
                    />
                  </v-col>

                  <v-col cols="12">
                    <TalhoesSelect
                        label="Talhão Destino"
                        v-model="form.talhaoDestinoId"
                        :empresa="form.empresaDestinoId"
                        :producao="form.producaoDestinoId"
                        :disabled="!form.producaoDestinoId"
                        :obrigatorio="[1,3].includes(form.finalidade)"
                        :rules="rule('talhaoDestinoId')"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="([1,3]).includes(form.finalidade)">
              <v-col cols="6">
                <bloco-informativo texto-informativo="Obrigadotorio informar uma origem e a data de emissão">
                  <v-autocomplete
                      label="Número Nota Fiscal *"
                      item-value="numero"
                      item-text="numero"
                      dense
                      outlined
                      :rules="rule('notaFiscal')"
                      v-model="form.notaFiscal"
                      return-object
                      :items="notasFiscaisOptions"
                      :disabled="!form.talhaoOrigemId"
                      :loading="loadingNotaFiscal"
                  />
                </bloco-informativo>
              </v-col>
              <v-col cols="6">
                <DatePicker
                    label="Data Emissão *"
                    v-model="form.dataNotaFiscal"
                    :rules="rule('dataNotaFiscal')"
                    :max="dataAtual"
                    :readonly="false"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                    dense
                    outlined
                    type="number"
                    min="0"
                    :max="saldoGramaColhido"
                    step="0.1"
                    label="Área Colhida Transferida *"
                    v-model.number="form.areaMovimentada"
                    :rules="rule('areaMovimentada')"
                    :disabled="!saldoGramaColhido"
                />
              </v-col>
              <v-col>
                <v-text-field
                    dense
                    outlined
                    type="number"
                    min="0"
                    step="0.1"
                    label="Saldo de Grama Colhido"
                    v-model="saldoGramaColhido"
                    :loading="loadingSaldoGrama"
                    disabled
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                    v-model="form.motivo"
                    outlined
                    label="Motivo"
                    :rules="rule('motivo')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn text color="blue darken-1" @click="close">
            Cancelar
          </v-btn>

          <v-btn text color="blue darken-1" @click="salvar">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>

      <v-card-text v-show="!showForm">
        <div class="text-center p-5">
          <h4 class="mb-4">
            Aguarde...
          </h4>

          <v-progress-circular
              indeterminate
              color="primary"
              :size="100"
              :width="2"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

import { CLEAR, ADD_RULES, ADD_COLUMNS, ADD_BACKEND_ERRORS } from '@/core/services/store/validation.module'
import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: (instance) => ({
    form: {
      empresaOrigemId: null,
      producaoOrigemId: null,
      talhaoOrigemId: null,

      empresaDestinoId: null,
      producaoDestinoId: null,
      talhaoDestinoId: null,

      finalidade: null,
      dataOcorrencia: instance.$date.getISOToday(),
      notaFiscal: null,
      dataNotaFiscal: instance.$date.getISOToday(),

      areaMovimentada: null,
      motivo: null,
    },
    rules: {},
    saldoGramaColhido: null,
    loadingSaldoGrama: false,
    loadingNotaFiscal: false,
    finalidadeOptions: [
      {
        id: 1,
        nome: 'Transferência de Nota Fiscal',
      },
      {
        id: 2,
        nome: 'Retirada de Mudas',
      },
      {
        id: 3,
        nome: 'Recebimento de Gramas',
      },
    ],
    notasFiscaisOptions: [],
    dataAtual: instance.$date.getISOToday(),
    showForm: true,
    dataRule: [(v) => !!v || 'Necessário informar a Data de Realização'],
    observacaoRule: [(v) => !instance.form.utilizacaoExterna || !!v || 'Necessário informar um Produto'],
  }),
  computed: {
    ...mapGetters(['getRules']),
    model: {
      get () {
        return this.value
      },
      set (val) {
        if (!val) {
          this.reload()
        }

        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  watch: {
    'form.finalidade' (val) {
      this.form.areaMovimentada = 0
      this.form.notaFiscal = null

      if (val === 2) {
        this.form.empresaDestinoId = null
      }

      if (val === 3) {
        this.form.empresaOrigemId = null
      }
    },
    'form.talhaoOrigemId' (val) {
      if (this.form.finalidade === 2) {
        this.saldoTalhao()
      }

      if (([1, 3]).includes(this.form.finalidade)) {
        this.selectNota()
      }
    },
    'form.dataNotaFiscal' (val) {
      this.selectNota()
    },
    'form.notaFiscal' (val) {
      this.form.dataNotaFiscal = val.data_emissao
      this.saldoGramaColhido = val.saldo
    },
    saldoGramaColhido (val) {
      this.$store.dispatch(ADD_RULES, {
        areaMovimentada: ['required', 'min:0', 'max:' + val],
      })
    },
  },
  mounted () {
    this.$store.dispatch(CLEAR)

    this.$store.dispatch(ADD_RULES, {
      finalidade: ['required'],
      dataOcorrencia: ['required'],
      empresaOrigemId: [],
      producaoOrigemId: [],
      talhaoOrigemId: [],
      empresaDestinoId: [],
      producaoDestinoId: [],
      talhaoDestinoId: [],
      notaFiscal: ['required'],
      dataNotaFiscal: [],
      areaMovimentada: ['required', 'min:0'],
      motivo: [],
    })

    this.$store.dispatch(ADD_COLUMNS, {
      finalidade: 'Finalidade',
      dataOcorrencia: 'Data de Ocorrência',
      empresaOrigemId: 'Empresa Origem',
      producaoOrigemId: 'Produção Origem',
      talhaoOrigemId: 'Talhão Origem',
      empresaDestinoId: 'Empresa Destino',
      producaoDestinoId: 'Produção Destino',
      talhaoDestinoId: 'Talhão Destino',
      notaFiscal: 'Nota Fiscal',
      dataNotaFiscal: 'Data de Emissão',
      areaMovimentada: 'Área Movimentada',
    })
  },
  methods: {
    rule (column) {
      return this.$store.getters.getRule(column)
    },
    close () {
      this.model = false
    },
    salvar () {
      this.$store.dispatch(ADD_BACKEND_ERRORS, {})

      if (!this.$refs.form.validate()) {
        return false
      }

      console.log(this.form)

      ApiService.post('agricola/retiradas/acerto', {
        finalidade: this.form.finalidade,
        dataOcorrencia: this.form.dataOcorrencia,
        talhaoOrigemId: this.form.talhaoOrigemId,
        talhaoDestinoId: this.form.talhaoDestinoId,
        notaFiscal: ([1, 3]).includes(this.form.finalidade) ? this.form.notaFiscal?.numero : null,
        dataNotaFiscal: ([1, 3]).includes(this.form.finalidade) ? this.form.dataNotaFiscal : null,
        areaMovimentada: this.form.areaMovimentada,
        motivo: this.form.motivo,
      }).then((response) => {
        this.$refs.snackbar.show(
          'Sucesso',
          response.data.data.message,
          'success',
          3000)
      }).catch((err) => {
        if (err.response.status !== 422) {
          return this.$refs.snackbar.show(
            'Erro',
            'Não foi possível realizar o Acerto de Área Colhida',
            'danger',
            3000)
        }

        this.$refs.form.validate()
      })
    },
    reload () {
      this.$refs.form.resetValidation()
      this.showDeleteButton = false

      this.form = {
        empresaOrigemId: null,
        producaoOrigemId: null,
        talhaoOrigemId: null,

        empresaDestinoId: null,
        producaoDestinoId: null,
        talhaoDestinoId: null,

        finalidade: null,
        dataOcorrencia: this.$date.getISOToday(),
        notaFiscal: null,
        dataNotaFiscal: this.$date.getISOToday(),

        areaMovimentada: null,
        motivo: null,
      }
    },
    saldoTalhao () {
      this.loadingSaldoGrama = true

      ApiService.get('agricola/retiradas/gramas/saldo/talhao', {
        talhaoId: this.form.talhaoOrigemId,
        data: this.form.dataOcorrencia,
      }).then((response) => {
        this.saldoGramaColhido = response.data.data.saldo
        this.loadingSaldoGrama = false
      }).catch(() => {
        this.loadingSaldoGrama = false
      })
    },
    selectNota () {
      this.loadingNotaFiscal = true

      ApiService.cancel('select-nota-fiscal').get('agricola/retiradas/gramas/select/nota-fiscal', {
        talhaoId: this.form.talhaoOrigemId,
        data: this.form.dataNotaFiscal,
      }).then((response) => {
        this.notasFiscaisOptions = response.data.data
        this.loadingNotaFiscal = false
      }).catch(() => {
        this.loadingNotaFiscal = false
      })
    },
  },
}
</script>
