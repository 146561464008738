<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      :max-width="900"
    >
    <custom-snackbar ref="snackbar" />
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="info" dark outlined v-bind="attrs" v-on="on">
          Acerto de Área Colhida
        </v-btn>
      </template>
      <v-card>
        <v-toolbar color="info" dark>
          <span class="headline">Acerto de Área Colhida</span>
        </v-toolbar>
        <v-progress-linear
          v-if="loading"
          height="10px"
          absolute
          indeterminate
          color="green"
        />
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col class="col-12 col-sm-6">
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="empresaOrigem"
                        :items="empresaOrigemOptions"
                        label="Empresa Origem"
                        item-text="nome"
                        item-value="id"
                        dense
                        outlined
                        :rules="empresaOrigemRule"
                        @change="buscarProducoesOrigem"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="producaoOrigem"
                        :items="producaoOrigemOptions"
                        label="Produção Origem"
                        item-text="nome"
                        item-value="id"
                        dense
                        outlined
                        :rules="producaoOrigemRule"
                        @change="buscarTalhoes('origem')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="talhaoOrigem"
                        :items="talhaoOrigemOptions"
                        label="Talhão Origem"
                        item-text="descricao"
                        item-value="id"
                        dense
                        outlined
                        :rules="talhaoOrigemRule"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="col-12 col-sm-6">
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="empresaDestino"
                        :items="empresaDestinoOptions"
                        label="Empresa Destino"
                        item-text="nome"
                        item-value="id"
                        dense
                        outlined
                        :rules="[empresaDestinoRule]"
                        @change="buscarProducoesDestino"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="producaoDestino"
                        :items="producaoDestinoOptions"
                        label="Produção Destino"
                        item-text="nome"
                        item-value="id"
                        dense
                        outlined
                        :rules="[producaoDestinoRule]"
                        @change="buscarTalhoes('destino')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        v-model="talhaoDestino"
                        :items="talhaoDestinoOptions"
                        label="Talhão Destino"
                        item-text="descricao"
                        item-value="id"
                        dense
                        outlined
                        :rules="[talhaoDestinoRule]"
                        @change="buscarTalhoesDestino"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-12 col-sm-6">
                  <v-select
                    v-model="finalidade"
                    :items="finalidadeOptions"
                    label="Finalidade"
                    item-text="finalidade"
                    item-value="finalidade"
                    dense
                    outlined
                    :rules="finalidadeRule"
                  />
                </v-col>
                <v-col :class="numeroColunasDataOcorrencia">
                  <DatePicker
                    label="Data Ocorrência"
                    v-model="dataOcorrencia"
                  />
                </v-col>
                <v-col class="col-12 col-sm-2" v-show="finalidade == 'Retirada de Mudas'">
                  <v-btn
                    outlined
                    block
                    class="v-btn--height-fix"
                    @click="buscarSaldoGramaColhido"
                  >
                    <v-icon dark>
                      mdi-cached
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="finalidade === 'Transferência de Nota Fiscal'">
                <v-col class="col-12 col-sm-6">
                  <v-text-field
                    dense
                    outlined
                    label="Número Nota Fiscal"
                    :append-icon="notaFiscalInput ? 'mdi-close' : ''"
                    @click:append="notaFiscalInput = ''"
                    v-model="notaFiscalInput"
                  />
                </v-col>
                <v-col class="col-12 col-sm-4">
                  <DatePicker
                    label="Data Emissão"
                    v-model="dataEmissao"
                  />
                </v-col>
                <v-col class="col-12 col-sm-2">
                  <v-btn
                    outlined
                    :disabled="disabledBtnBuscarNotaFical"
                    block
                    class="v-btn--height-fix"
                    @click="buscarNotasFiscais"
                  >
                    <v-icon dark>
                      mdi-cached
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="buscarNotasFiscaisPelaData">
                  <v-select
                    v-model="notaFiscalSelect"
                    :items="notaFiscalOptions"
                    label="Notas Fiscais"
                    item-text="nota"
                    item-value="nota"
                    dense
                    outlined
                    :disabled="disabledCmbNotasFiscais"
                    @change="getSaldoByNotaFiscal"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="col-12 col-sm-6">
                  <v-text-field
                    dense
                    outlined
                    label="Área Colhida Transferida"
                    v-model="areaColhidaTransferida"
                    type="number"
                    :rules="[areaColhidaRule]"
                    @keydown="$stringFormat.validarNumero"
                  />
                </v-col>
                <v-col class="col-12 col-sm-6">
                  <v-text-field
                    dense
                    outlined
                    disabled
                    label="Saldo de Grama Colhido"
                    v-model="saldoGramaColhido"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="motivo"
                    label="Motivo"
                    dense
                    outlined
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="fecharDialogLimparFormulario"> Cancelar </v-btn>
            <v-btn color="blue darken-1" text @click="validarSalvar"> Salvar </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmacaoRetiradasMudas" max-width="900px">
      <v-card class="bg-danger text-white">
        <v-card-title class="headline word-breaker d-flex justify-center text-center">
          A quantidade de grama informada ultrapassa a quantidade disponível na data informada. <strong class="mt-3">Deseja continuar?</strong>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="white"
            text
            outlined
            large
            @click="dialogConfirmacaoRetiradasMudas = false"
          >
            Não
          </v-btn>
          <v-btn color="white" text outlined large @click="salvar">
            Sim
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/javascript">
import ApiService from '@/core/services/api.service'

export default {
  data: function () {
    return {
      dialog: false,
      dialogConfirmacaoRetiradasMudas: false,
      buscarNotasFiscaisPelaData: false,
      loading: false,
      disabledBtnBuscarNotaFical: false,
      disabledCmbNotasFiscais: false,
      valid: '',
      notasFiscais: [],
      numeroColunasDataOcorrencia: 'col-12 col-sm-6',

      finalidadeRule: [(v) => !!v || 'Selecione a Finalidade do Acerto'],
      empresaOrigemRule: [(v) => !!v || 'Selecione a Empresa de Origem'],
      producaoOrigemRule: [(v) => !!v || 'Selecione a Produção de Origem'],
      talhaoOrigemRule: [(v) => !!v || 'Selecione o Talhão de Origem'],

      finalidadeOptions: [
        { finalidade: 'Transferência de Nota Fiscal' },
        { finalidade: 'Retirada de Mudas' },
      ],
      empresaOrigemOptions: [],
      empresaDestinoOptions: [],
      producaoOrigemOptions: [],
      producaoDestinoOptions: [],
      talhaoOrigemOptions: [],
      talhaoDestinoOptions: [],
      notaFiscalOptions: [],

      empresaOrigem: '',
      empresaDestino: '',
      producaoOrigem: '',
      producaoDestino: '',
      talhaoOrigem: '',
      talhaoDestino: '',
      finalidade: '',
      dataOcorrencia: this.$date.getISOToday(),
      dataEmissao: this.$date.getISOToday(),
      saldoGramaColhido: '',
      areaColhidaTransferida: '',
      notaFiscalSelect: '',
      notaFiscalInput: '',
      motivo: '',
    }
  },

  computed: {
    areaColhidaRule () {
      let valid = true
      let msg = ''

      const areaColhida = parseInt(this.areaColhidaTransferida)
      const saldoGrama = parseInt(this.saldoGramaColhido)

      if (saldoGrama === '') {
        valid = false
        msg = 'Informe os dados corretamente para obter o Saldo de Grama'
        return () => valid || `${msg}`
      }

      if (saldoGrama === 0 && this.finalidade === 'Transferência de Nota Fiscal') {
        valid = false
        msg = 'Não possui Saldo de Grama para realizar o Acerto'
        return () => valid || `${msg}`
      }

      if (this.areaColhidaTransferida === '') {
        valid = false
        msg = 'Informe a Área Colhida Transferida'
        return () => valid || `${msg}`
      }

      if (areaColhida <= 0) {
        valid = false
        msg = 'Área Colhida Transferida deve ser maior que 0'
        return () => valid || `${msg}`
      }

      if (saldoGrama < areaColhida && this.finalidade === 'Transferência de Nota Fiscal') {
        valid = false
        msg = 'Área Colhida Transferida não pode ser maior que o Saldo de Grama'
        return () => valid || `${msg}`
      }

      return () => valid || `${msg}`
    },

    empresaDestinoRule () {
      let valid = true
      let msg = ''

      if (this.empresaDestino !== '') {
        valid = true
        return () => valid || `${msg}`
      }

      if (this.finalidade === 'Transferência de Nota Fiscal') {
        valid = false
        msg = 'Selecione a Empresa de Destino'
        return () => valid || `${msg}`
      }

      return () => valid || `${msg}`
    },

    producaoDestinoRule () {
      let valid = true
      let msg = ''

      if (this.producaoDestino !== '') {
        valid = true
        return () => valid || `${msg}`
      }

      if (this.finalidade === 'Transferência de Nota Fiscal') {
        valid = false
        msg = 'Selecione a Produção de Destino'
      }

      return () => valid || `${msg}`
    },

    talhaoDestinoRule () {
      let valid = true
      let msg = ''

      if (this.talhaoDestino !== '') {
        valid = true
        return () => valid || `${msg}`
      }

      if (this.finalidade === 'Transferência de Nota Fiscal') {
        valid = false
        msg = 'Selecione o Talhão de Destino'
      }

      return () => valid || `${msg}`
    },
  },

  watch: {
    dialog (val) {
      val || this.fecharDialog()
    },

    dialogDelete (val) {
      val || this.closeDelete()
    },

    dataEmissao (data) {
      if (!this.notaFiscalInput) {
        let porData = ''
        data && !this.notaFiscalSelect ? porData = true : porData = false
        this.buscarNotasFiscaisPelaData = porData
      }
    },

    notaFiscalInput (val) {
      if (val) {
        this.buscarNotasFiscaisPelaData = false
        this.dataEmissao = ''
      }
    },

    finalidade (val) {
      let cols = ''
      val === 'Retirada de Mudas' ? cols = 'col-12 col-sm-4' : cols = 'col-12 col-sm-6'
      this.numeroColunasDataOcorrencia = cols
      this.disabledCmbNotasFiscais = true
      this.notaFiscalOptions = []
      this.notasFiscais = []
      this.notaFiscalSelect = ''
      this.saldoGramaColhido = ''
      this.areaColhidaTransferida = ''
      this.dataEmissao = this.$date.getISOToday()
      this.$refs.form.resetValidation()
    },
  },

  mounted () {
    this.empresaOrigemOptions = this.$empresas.list(false)
    this.empresaDestinoOptions = this.$empresas.list(false)
  },

  methods: {
    buscarProducoesOrigem (empresaId) {
      this.producaoOrigemOptions = this.$producoes.list(empresaId, false)
    },

    buscarProducoesDestino (empresaId) {
      this.producaoDestinoOptions = this.$producoes.list(empresaId, false)
    },

    buscarTalhoes (tipo) {
      let url = ''

      if (tipo === 'origem') {
        url = `/talhao-producao/${this.empresaOrigem}/${this.producaoOrigem}`
      } else {
        url = `/talhao-producao/${this.empresaDestino}/${this.producaoDestino}`
      }

      ApiService
        .get(url)
        .then((res) => {
          const talhoes = res.data.data

          if (tipo === 'origem') {
            this.talhaoOrigemOptions = talhoes
          } else {
            this.talhaoDestinoOptions = talhoes
          }
        })
    },

    buscarNotasFiscais () {
      this.loading = true
      this.disabledBtnBuscarNotaFical = true
      this.saldoGramaColhido = ''
      this.areaColhidaTransferida = ''
      this.notasFiscais = []
      this.notaFiscalOptions = []

      const empresaId = this.empresaOrigem
      const producaoId = this.producaoOrigem
      const talhaoId = this.talhaoOrigem
      const nf = this.notaFiscalInput
      const data = this.dataEmissao

      let params = `?empresa_id=${empresaId}&producao_id=${producaoId}&talhao_id=${talhaoId}&`

      if (nf) {
        this.buscarNotasFiscaisPelaData = false
        this.notaFiscalSelect = ''
        params += `numero_nota_fiscal=${nf}`
      } else {
        this.buscarNotasFiscaisPelaData = true
        params += `data_emissao=${data}`
      }

      ApiService
        .get('/sapiens/nota-fiscal/notas-fiscais', params)
        .then((res) => {
          const notas = res.data.data

          if (notas.length > 0) {
            if (nf) {
              this.dataEmissao = this.$stringFormat.dateToISO(new Date(notas[0].data).toLocaleString())
              this.saldoGramaColhido = notas[0].area
            } else {
              this.disabledCmbNotasFiscais = false
              this.notaFiscalOptions = notas
              this.notasFiscais = notas
            }
          } else {
            this.disabledCmbNotasFiscais = true

            const msg = 'Nenhuma Nota Fiscal dísponivel para os dados informados'

            this.notaFiscalOptions.push({
              nota: msg,
            })

            this.notaFiscalSelect = msg
            this.buscarNotasFiscaisPelaData = true
          }

          this.disabledBtnBuscarNotaFical = false
          this.loading = false
        })
    },

    buscarSaldoGramaColhido () {
      if (this.finalidade === 'Retirada de Mudas') {
        this.loading = true

        const empresaId = this.empresaOrigem
        const producaoId = this.producaoOrigem
        const talhaoId = this.talhaoOrigem
        const data = this.dataOcorrencia

        const params = `?empresa_id=${empresaId}&producao_id=${producaoId}&talhao_id=${talhaoId}&data_ocorrencia=${data}`

        ApiService
          .get('/sapiens/nota-fiscal/saldo-disponivel-colheita', params)
          .then((res) => {
            this.saldoGramaColhido = res.data.data
            this.loading = false

            if (this.saldoGramaColhido === 0) {
              this.$refs.snackbar.show(
                'Atenção',
                'Não há oferta de grama para o talhão selecionado.',
                'alert',
              )
            }
          })
      }
    },

    getSaldoByNotaFiscal (nf) {
      const empresaId = this.empresaOrigem
      const producaoId = this.producaoOrigem
      const talhaoId = this.talhaoOrigem

      const params = `?empresa_id=${empresaId}&producao_id=${producaoId}&talhao_id=${talhaoId}&numero_nota_fiscal=${nf}`

      ApiService
        .get('/sapiens/nota-fiscal/notas-fiscais', params)
        .then((res) => {
          const notas = res.data.data

          if (notas.length > 0) {
            this.dataEmissao = this.$stringFormat.dateToISO(new Date(notas[0].data).toLocaleString())
            this.saldoGramaColhido = notas[0].area
          } else {
            this.disabledCmbNotasFiscais = true

            const msg = 'Nenhuma Nota Fiscal dísponivel para os dados informados'

            this.notaFiscalOptions.push({
              nota: msg,
            })

            this.notaFiscalSelect = msg
            this.buscarNotasFiscaisPelaData = true
          }

          this.disabledBtnBuscarNotaFical = false
          this.loading = false
        })
    },

    validarSalvar () {
      if (this.$refs.form.validate()) {
        if (this.finalidade === 'Retirada de Mudas') {
          if (parseInt(this.saldoGramaColhido) < this.areaColhidaTransferida) {
            this.dialogConfirmacaoRetiradasMudas = true
          } else {
            this.salvar()
          }
        } else {
          this.salvar()
        }
      }
    },

    salvar () {
      this.fecharDialog()

      const form = new FormData()

      form.append('empresa_origem_id', this.empresaOrigem)
      form.append('producao_origem_id', this.producaoOrigem)
      form.append('talhao_origem_id', this.talhaoOrigem)
      form.append('empresa_destino_id', this.empresaDestino)
      form.append('producao_destino_id', this.producaoDestino)
      form.append('talhao_destino_id', this.talhaoDestino)
      form.append('finalidade', this.finalidade)
      form.append('data_ocorrencia', this.dataOcorrencia)
      form.append('nota_fiscal_input', this.notaFiscalInput)
      form.append('nota_fiscal_select', this.notaFiscalSelect)
      form.append('area_colhida', this.areaColhidaTransferida)
      form.append('motivo', this.motivo)

      ApiService
        .post('registro-colheita/acerto-area-colhida', form)
        .then(
          (res) => {
            this.limparFormulario()

            if (res.data.data === 'Processado com Sucesso.') {
              this.$emit(
                'setSnackbarAcertoAreaColhida',
                {
                  color: 'success',
                  title: 'Sucesso',
                  text: 'Acerto de Área Colhida salvo com sucesso',
                  timeout: 3000,
                },
              )
            } else {
              this.$emit(
                'setSnackbarAcertoAreaColhida',
                {
                  color: 'danger',
                  title: 'Erro',
                  text: res.data.data,
                  timeout: 3000,
                })
            }
          })
        .catch((error) => {
          this.$emit(
            'setSnackbarAcertoAreaColhida',
            {
              color: 'danger',
              title: 'Erro',
              text: 'Não foi possível realizar o Acerto de Área Colhida',
              timeout: 3000,
            })

          console.error('Erro: ', error)
        })
    },

    fecharDialog () {
      this.dialog = false
      this.dialogConfirmacaoRetiradasMudas = false
    },

    limparFormulario () {
      this.loading = false
      this.disabledBtnBuscarNotaFical = false
      this.empresaOrigem = ''
      this.producaoOrigem = ''
      this.talhaoOrigem = ''
      this.empresaDestino = ''
      this.producaoDestino = ''
      this.talhaoDestino = ''
      this.finalidade = ''
      this.dataOcorrencia = this.$date.getISOToday()
      this.dataEmissao = this.$date.getISOToday()
      this.areaColhidaTransferida = ''
      this.notaFiscalInput = ''
      this.notaFiscalSelect = ''
      this.saldoGramaColhido = ''
      this.motivo = ''
      this.$refs.form.resetValidation()
    },

    fecharDialogLimparFormulario () {
      this.fecharDialog()
      this.limparFormulario()
    },
  },
}
</script>
